<template>
    <div class="pa-4 text-center">
        <v-btn-group variant="outlined" color="primary" density="comfortable" rounded="pill" divided>
            <v-btn class="pe-2" prepend-icon="mdi-account-multiple-outline" variant="flat">
                <div class="text-none font-weight-regular">
                    Herramientas TI
                </div>

                <v-dialog activator="parent" max-width="500" persistent>
                    <template v-slot:default="{ isActive }">
                        <v-card rounded="lg">
                            <v-card-title class="d-flex justify-space-between align-center">
                                <div class="text-h5 text-medium-emphasis ps-2">
                                    Panel TI
                                </div>

                                <v-btn icon="mdi-close" variant="text" @click="isActive.value = false"></v-btn>
                            </v-card-title>

                            <v-divider class="mb-4"></v-divider>
                            <!-- Panel Expansible 1 - Liberar IP -->
                            <!-- <v-expansion-panels multiple>
 
        <v-expansion-panel>
      <v-expansion-panel-content v-for="(header,i) in headers" :key="i">
        <template v-slot:header >
          <div>{{header.text}}</div>
        </template>
<template v-for="element in elements" :key="element">
          <v-card v-if="element.panel == header.text">
          <v-card-text >
            <v-layout>
           
              {{element.text}}
            </v-layout>
          </v-card-text>
        </v-card>
        </template>
</v-expansion-panel-content>
</v-expansion-panel>

<v-expansion-panel>
    <v-expansion-panel-header>Header 2</v-expansion-panel-header>
    <v-expansion-panel-content>Content 2</v-expansion-panel-content>
</v-expansion-panel>

</v-expansion-panels> -->

                            <v-expansion-panels multiple>
                                <v-expansion-panel cols="12" md="12" lg="12">
                                    <v-expansion-panel-title>
                                        <v-row no-gutters>
                                            <v-col style="color: #6580A0" cols="12" class="d-flex justify-start">
                                                Liberar IP
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text>
                                        <!-- Lista de elementos -->
                                        <v-list>
                                            <v-list-item-group>
                                                <v-list-item v-for="(element, i) in elements" :key="i">
                                                    <v-list-item-content>
                                                        <!-- Mostrar el texto o el campo de edición -->
                                                        <v-list-item-title v-if="editingIndex !== i">
                                                            {{ element.blk_usr }} -- {{ element.blk_ip }}
                                                        </v-list-item-title>


                                                    </v-list-item-content>

                                                    <v-list-item-action>
                                                        <!-- Botón para editar -->
                                                        <v-btn @click="cerrarApi(element)" color="primary" icon>
                                                            <v-icon>mdi-pencil</v-icon>
                                                        </v-btn>

                                                        <!-- Botón para guardar la edición -->

                                                    </v-list-item-action>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-expansion-panel-text>
                                </v-expansion-panel>
                                <v-expansion-panel cols="12" md="12" lg="12">
                                    <v-expansion-panel-title>
                                        <v-row no-gutters>
                                            <v-col style="color: #6580A0" cols="12" class="d-flex justify-start">
                                                Actualizar contraseña
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text>
                                        <v-card>
                                            <v-form ref="form">
                                                <v-card-title>
                                                    <span class="text-h5">Usuario a desbloquear</span>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <v-autocomplete v-model="permisos" :items="list"
                                                                    color="blue-grey lighten-2" label="Empleado"
                                                                    item-title="Busqueda" item-value="emp_num"
                                                                    variant="outlined" :rules="rules" required>

                                                                    <template v-slot:chip="{ props, item }">
                                                                        <v-list-item v-bind="props"
                                                                            :title="item.raw.Nombre"
                                                                            :subtitle="item.raw.emp_num"></v-list-item>
                                                                    </template>
                                                                    <template v-slot:item="{ props, item }">
                                                                        <v-list-item v-bind="props"
                                                                            :title="item.raw.Nombre"
                                                                            :subtitle="item.raw.emp_num"></v-list-item>
                                                                    </template>

                                                                </v-autocomplete>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                    <!-- <small>*indicates required field</small> -->
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="blue-darken-1" variant="text" @click='closeDialog'>
                                                        Cerrar
                                                    </v-btn>
                                                    <v-btn color="blue-darken-1" variant="text"
                                                        @click='cambiarContrasena()'>

                                                        desbloquear
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-form>
                                        </v-card>
                                    </v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>




                            <!-- <v-card-text>
                <div class="text-medium-emphasis mb-4">
                  Invite collaborators to your network and grow your connections.
                </div>

                <div class="mb-2">Message (optional)</div>

                <v-textarea
                  :counter="300"
                  class="mb-2"
                  rows="2"
                  variant="outlined"
                  persistent-counter
                ></v-textarea>

                <div class="text-overline mb-2">💎 PREMIUM</div>

                <div class="text-medium-emphasis mb-1">
                  Share with unlimited people and get more insights about your network. Try Premium Free for 30 days.
                </div>

                <v-btn
                  class="text-none font-weight-bold ms-n4"
                  color="primary"
                  text="Retry Premium Free"
                  variant="text"
                ></v-btn>
              </v-card-text> -->

                            <v-divider class="mt-2"></v-divider>

                            <v-card-actions class="my-2 d-flex justify-end">
                                <!-- <v-btn class="text-none" rounded="xl" text="Cancel"
                                    @click="isActive.value = false"></v-btn>

                                <v-btn class="text-none" color="primary" rounded="xl" text="Send" variant="flat"
                                    @click="isActive.value = false"></v-btn> -->
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-btn>

            <v-btn size="small" icon>
                <v-icon icon="mdi-menu-down"></v-icon>

                <v-menu activator="parent" location="bottom end" transition="fade-transition">
                    <v-list density="compact" min-width="250" rounded="lg" slim>
                        <v-list-item prepend-icon="mdi-link" title="Liberar IP" link></v-list-item>
                        <v-list-item prepend-icon="mdi-link" title="To MOre" link></v-list-item>

                        <v-divider class="my-2"></v-divider>

                        <v-list-item min-height="24">
                            <template v-slot:subtitle>
                                <div class="text-caption">
                                    Shared with John + 1 more
                                </div>
                            </template>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-btn>
        </v-btn-group>
    </div>
</template>

<script>

import { getListApiBloc, cerrarApiBloc } from '@/api/panelTi'
import { getListEmp, getListPermisos, getListAds } from '@/api/bienesVehiculosApi'
export default {

    data() {
        return {
            items: [
                { name: 'Item 1' },
                { name: 'Item 2' },
                { name: 'Item 3' }
            ],
            dialog: false,

            headers: [
                { text: 'Animals' },
                { text: 'Food' },
                { text: 'Colors' }
            ],
            elements: [],
            editedItem: [],
            usuario: {
                id: 0,
                idEmp: null,
            },
            list: [],
        };
    },

    mounted() {
        this.getList();
        this.getUsrs();
        window.onerror = (e) => console.log(e);
    },
    methods: {




        async getUsrs() {

            try {
                this.list = await getListEmp().then((resp) => {
                    if (resp.data.length === 0) {
                        this.showAlert({
                            color: "warning",
                            mensaje: "No se encontraron registros empleados"
                        });
                    }

                    return resp.data;
                });



            } catch (e) {
                this.showAlert({
                    color: "error",
                    mensaje: e.toString(),
                    icon: "mdi-close-circle"
                });
            }
        },

        async getList() {

            try {
                this.elements = await getListApiBloc().then((resp) => {
                    if (resp.data.length === 0) {
                        this.showAlert({
                            color: "warning",
                            mensaje: "No se encontraron registros empleados"
                        });
                    }

                    return resp.data;
                });


            } catch (e) {
                this.showAlert({
                    color: "error",
                    mensaje: e.toString(),
                    icon: "mdi-close-circle"
                });
            }
        },
        showAlert(alert) {
            this.$emit("show-alert", alert);
        },
        async cambiarContrasena() {
            // try {
            //  const { valid } = await this.$refs.form.validate()
            console.log(this.permisos);


            this.list = await getListEmp().then((resp) => {
                if (resp.data.length === 0) {
                    this.showAlert({
                        color: "warning",
                        mensaje: "No se encontraron registros empleados"
                    });
                }

                return resp.data;
            });

            // if (valid) {
            //     this.permisos.ids = this.permisos.ids.toString()

            //     if(this.update){
            //         let resp = await updatePermisos(this.permisos);
            //         this.respFunction(resp)
            //     }else {
            //         let resp = await insertPermisos(this.permisos);

            //         this.respFunction(resp)
            //     }

            // }

            // }
            // catch (e) {
            //     this.showAlert({
            //         color: "error",
            //         mensaje: e.toString(),
            //         icon: "mdi-close-circle"
            //     });
            // }

        },
        async cerrarApi(element){
            try{
                let resp = await cerrarApiBloc(element);

                console.log(resp)

            }catch(e){
                console.log(e)
            }
        }
    }
};
</script>
<style scoped>
/* Estilos personalizados */
.v-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.v-list-item-content {
    flex-grow: 1;
}

.v-btn {
    margin-left: 8px;
}
</style>