import { axiosSingleton, axiosSingletonC } from '../plugins/axios'

/*
|--------------------------------------------------------------------------
| API Routes Bienes
|--------------------------------------------------------------------------
*/
const bienApi = 'api/BienesController'

// Bienes registradosHna
export function getAllBienesH(emp) {
    console.log("axio nmp",emp)
    return axiosSingletonC.getInstance().get(`/muebles/${emp}`)
}

export function getBienByCodHana (activo,sub) {
    return axiosSingletonC.getInstance().get(`/muebles/${activo}/${sub}`,{
        headers: {
            'Access-Control-Allow-Origin': '*',
        }
    })
}

export function getBienesByHana(){
return axiosSingletonC.getInstance().get('/muebles')
}


export function getBienByCodHanaSerie (activo) {
    return axiosSingletonC.getInstance().get(`/mueblesSerie/${activo}`)
}

export function getBienesByAdsH (emple) {
    console.log("api emple",emple);
    // return axiosSingletonC.getInstance().post(`/mueblesArea`,emple)
    return axiosSingletonC.getInstance().post(`/mueblesAreasHS`,emple,{
        headers: {
            'Access-Control-Allow-Origin': '*',
        }
    })
    
}



/////////////////////////////////////////////////////////////////////////////////////////////////////

// Bienes intermedio

export function getBienesByCgaHanalarave (emp) {
    console.log("api ByCgaHanalarave",emp);
    // return axiosSingletonC.getInstance().post(`/mueblesArea`,emple)
    return axiosSingleton.getInstance().get(`${bienApi}/mueblesAreasHana/${emp}`)
    
}


//////////////////////////////////////////////////////////////////////////////////////////////////
// Bienes registrados
export function getAllBienes (emp) {
    return axiosSingleton.getInstance().get(`${bienApi}/GetBienes/${emp}`)
}

export function getBienesByEmp (emp) {
    return axiosSingleton.getInstance().get(`${bienApi}/GetBienesByEmp/${emp}`)
}

export function getBienesByAds (ID) {
    return axiosSingleton.getInstance().get(`${bienApi}/GetBienesByAds/${ID}`)
}

export function getBienByCod (activo,sub) {
    return axiosSingleton.getInstance().get(`${bienApi}/buscarBienByCod/${activo}/${sub}`)
}

export function getBienById (ID) {
    return axiosSingleton.getInstance().get(`${bienApi}/buscarBienByID/${ID}`)
}

export function getEmpleado (emp) {
    return axiosSingleton.getInstance().get(`${bienApi}/buscarEmpleado/${emp}`)
}

export function putReAsignacion (emp,bien) {
    console.log(bien, "putfile");
    return axiosSingleton.getInstance().post(`${bienApi}/putReAsignacionMov/${emp}`, bien,
        {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
}

export function inByHanna (emp,bien) {
    console.log(bien, "postfile");
    return axiosSingleton.getInstance().post(`${bienApi}/inByHanna/${emp}`, bien,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'content-type': 'multipart/form-data'
            }
        });
}


export function putAprobarReAsignacion (aprueba,emp,bien) {
    return axiosSingleton.getInstance().put(`${bienApi}/aprobarAsignacion/${aprueba}/${emp}`, bien)
}

// Consulta Adscripciones
export function getListAds () {
    return axiosSingleton.getInstance().get(`${bienApi}/GetAds`)
}


export function getUsersByAds (idads) {
    return axiosSingleton.getInstance().get(`${bienApi}/getListEmpleadosByAsc/${idads}`)
}


export function getListEmp () {
    return axiosSingleton.getInstance().get(`${bienApi}/ListEmpleados`)
}

export function getListPermisos () {
    return axiosSingleton.getInstance().get(`${bienApi}/ListPermisos`)
}

// Consulta Permisos
export function getPermisos (emp) {
    return axiosSingleton.getInstance().get(`${bienApi}/getPermisos/${emp}`)
}

// Usuarios
  export function getPermisosBienes(){
    return axiosSingleton.getInstance().get(`${bienApi}/GetPermisosBienes`)
  }

export function postEstatusPermiso (item) {
    return axiosSingleton.getInstance().post(`${bienApi}/UpdateEstatusPermisos`, item)
}

export function insertPermisos (item) {
    return axiosSingleton.getInstance().post(`${bienApi}/InsertPermisos`, item)
}

export function updatePermisos (item) {
    return axiosSingleton.getInstance().post(`${bienApi}/UpdatePermisos`, item)
}

// export function putUser (id, item) {
//     return axiosSingleton.getInstance().put(`/${usuarios}/${id}`, item)
// }

// export function deleteUser (id) {
//     return axiosSingleton.getInstance().delete(`/${usuarios}/${id}`)
// }


/*
|--------------------------------------------------------------------------
| API Routes Vehiculos
|--------------------------------------------------------------------------
*/

const vehiApi = 'api/VehiculosController'

// Consulta Estatus registros de Vehiculos
export function getRegEstDash () {
    return axiosSingleton.getInstance().get(`${vehiApi}/getRegEstDash`)
}
